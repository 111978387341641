import React, { useEffect, useRef } from "react";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import Sketch from "@arcgis/core/widgets/Sketch";
import Search from "@arcgis/core/widgets/Search";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";

import { setAssetPath as setCalciteComponentsAssetPath } from '@esri/calcite-components/dist/components';
setCalciteComponentsAssetPath("https://js.arcgis.com/calcite-components/2.13.2/assets");

const ArcGISMap = ({ onGeometryCreate, onGeometryDelete }) => {
    const mapRef = useRef(null);

    useEffect(() => {
        if (mapRef.current) {
            const graphicsLayer = new GraphicsLayer();

            const map = new Map({
                basemap: "streets",
                layers: [graphicsLayer],
            });

            const view = new MapView({
                container: mapRef.current,
                map: map,
                center: [-118.805, 34.027], // Longitude, latitude
                zoom: 13,
                ui: {
                    components: [], // Disable all default UI components
                },
            });

            // Add Search widget to the map
            const searchWidget = new Search({
                view: view, // Attach the search widget to the view
            });

            // Add the search widget to the top-right corner of the view
            view.ui.add(searchWidget, {
                position: "top-left",
            });

            const sketch = new Sketch({
                view: view,
                layer: graphicsLayer,
                availableCreateTools: ["polygon"],
                visibleElements: {
                    createTools: {
                        point: false,
                        polyline: false,
                        circle: false,
                    },
                    selectionTools: {
                        "lasso-selection": false,
                        "rectangle-selection": false,
                    },
                    undoRedoMenu: false,
                    settingsMenu: false,
                }

            });

            view.ui.add(sketch, "top-right");

            // Listen to the sketch's 'create' event
            sketch.on("create", (event) => {
                if (event.state === "complete") {
                    const graphic = event.graphic;
                    onGeometryCreate(graphic.uid, graphic.toJSON().geometry);
                }
            });

            sketch.on("delete", (event) => {
                event.graphics.forEach(({ uid }) => onGeometryDelete(uid));
            });
        }
    }, []);

    return <div style={{ height: "100%", width: "100%" }} ref={mapRef}></div>;
};

export default ArcGISMap;
