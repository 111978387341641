import PropTypes from "prop-types";
import React, { memo } from "react";
import { FormFeedback, Input as ReactStrapInput, Label } from "reactstrap";

const Input = ({ form, label = '', afterLabel = '', placeholder = '', name, ...otherProps }) => {
    return (
        <>
            <Label className="form-label">{label}{afterLabel && (<span className="fw-normal">{afterLabel}</span>)}</Label>
            <ReactStrapInput
                name={name}
                type="text"
                placeholder={placeholder}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                invalid={
                    form.touched[name] &&
                    form.errors[name]
                        ? true
                        : false
                }
                {...otherProps}
            />
            {form.touched[name] &&
            form.errors[name] ? (
                <FormFeedback type="invalid">
                    {form.errors[name]}
                </FormFeedback>
            ) : null}
        </>
    );
};


Input.propTypes = {
    form: PropTypes.shape({
        handleChange: PropTypes.func.isRequired,
        handleBlur: PropTypes.func.isRequired,
        touched: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired,
    }).isRequired,
    label: PropTypes.string,
    afterLabel: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string.isRequired,
};

export default memo(Input);
