import React, { memo } from "react";
import { Input as ReactStrapInput, Label } from "reactstrap";
import PropTypes from "prop-types";

const InputRadio = ({ form, label = '', name, afterLabel = '' }) => {

    const options = [
        {
            label: 'Yes',
            value: true,
        },
        {
            label: 'No',
            value: false,
        }
    ];

    const inputs = options.map(({label, value}) => {
        const id = `radio-input-${name}-${label}`;
        return (
            <React.Fragment key={id}>
                <ReactStrapInput
                    id={id}
                    name={name}
                    type="radio"
                    onChange={() => {
                        form.setFieldValue(name, value);
                    }}
                    onClick={() => {
                        form.setFieldValue(name, value);
                    }}
                    className="me-2"
                    checked={form?.values[name] === value}
                    value={value}
                />
                <label htmlFor={id} className="me-5">{label}</label>
            </React.Fragment>
        );
    });

    return (
        <>
            <Label className="form-label">{label}{afterLabel && (<span className="fw-normal">{afterLabel}</span>)}</Label><br/>
            {inputs}
        </>
    );
};

InputRadio.propTypes = {
    form: PropTypes.shape({
        handleChange: PropTypes.func.isRequired,
        handleBlur: PropTypes.func.isRequired,
        touched: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired,
    }).isRequired,
    label: PropTypes.string,
    afterLabel: PropTypes.string,
    name: PropTypes.string.isRequired,
};

export default memo(InputRadio);
