import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row } from "reactstrap";
import TableContainer from "../../../../components/Common/TableContainer";
import Spinner from "../../../../components/Common/Spinner";
import { GET_COLUMNS } from "../../config";
import ScreenOverlay from "../../../../components/Common/ScreenOverlay";
import { selectIsQuoteListLoading, selectQuoteList } from "../../../../store/quote/selectors";

const QuoteList = ({
    data,
    isLoading,
}) => {
    return (
        <Row>
            <Col lg="12">
                <Card>
                    <CardBody>
                        <TableContainer
                            columns={GET_COLUMNS}
                            data={data || []}
                            isGlobalFilter={true}
                            isPagination={true}
                            SearchPlaceholder="Search..."
                            isCustomPageSize={true}
                            isAddButton={false}
                            tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                            theadClass="table-light"
                            paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                            pagination="pagination"
                        />
                    </CardBody>
                </Card>
            </Col>
            {isLoading && (
                <>
                    <ScreenOverlay />
                    <Spinner />
                </>
            )}
        </Row>
    );
};

QuoteList.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
};

export default compose(
    connect(createStructuredSelector({
        isLoading: selectIsQuoteListLoading(),
        data: selectQuoteList(),
    }),
    null,
))(QuoteList);
