// @flow
import { takeEvery, call, put } from "redux-saga/effects";
import {
  ADD_GEOMETRY,
  GET_QUOTE_LIST,
} from "./actionTypes";
import { toast } from "react-toastify";
import { addTestGeometry, getAllQuotes } from "../../api/quote";
import {
  fetchQuoteListFail,
  fetchQuoteListSuccess,
} from "./actions";

function* onAddGeometry({ payload }) {
  try {
    const response = yield call(addTestGeometry, payload);
    toast.success(response, {autoClose: 2000});
  } catch (error) {
    toast.error("Cannot translate geometry", {autoClose: 2000});
  }
}

function* onFetchQuoteList() {
    try {
      const response = yield call(getAllQuotes);
      yield put(fetchQuoteListSuccess(response));
    } catch (error) {
      yield put(fetchQuoteListFail(error));
      toast.error("Cannot fetch quotes", {autoClose: 2000});
    }
}

export function* QuoteSaga() {
  yield takeEvery(ADD_GEOMETRY, onAddGeometry);
  yield takeEvery(GET_QUOTE_LIST, onFetchQuoteList);
}

export default QuoteSaga;
