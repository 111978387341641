import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";

import Device from "./device/reducer";
import Dashboard from "./dashboard/reducer";
import User from "./user/reducer";
import Quote from "./quote/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  User,
  Device,
  Dashboard,
  Quote,
});

export default rootReducer;
