import { useEffect } from "react";
import { Container } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import QuoteList from "./components/list";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { fetchQuoteList } from "../../store/quote/actions";

const QuotesPage = ({ t, fetchQuoteListDispatch }) => {
    document.title = "Quotes";

    useEffect(() => {
        fetchQuoteListDispatch();
    }, []);

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs
                    title={t("Quotes")}
                />
                <QuoteList />
            </Container>
        </div>
    );
};

QuotesPage.propTypes = {
    t: PropTypes.any,
    fetchQuoteListDispatch: PropTypes.func,
};

export default withTranslation()(compose(
    connect(null,
    (dispatch) => ({
        fetchQuoteListDispatch: bindActionCreators(fetchQuoteList, dispatch),
    }),
))(QuotesPage));
