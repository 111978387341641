import React, { useState, useMemo } from "react";
import { Col, Container, Row } from "reactstrap";
import BackLink from "../../components/Common/BackLink";
import ArcGISMap from "./components/map";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { addGeometry } from "../../store/quote/actions";
import { withTranslation } from "react-i18next";
import ToastContainer from "../../components/Common/ToastContainer";

const PocMapPage = ({ addGeometryDispatch }) => {
    const [graphics, setGraphics] = useState({});

    const onGeometryCreate = (id, geometry) => {
        setGraphics(graphics => ({
            ...graphics,
            [id]: geometry,
        }));

        addGeometryDispatch(geometry);
    };

    const onGeometryDelete = (id) => {
        setGraphics(graphics => {
            const newGraphics = {...graphics};
            delete newGraphics[id];
            return newGraphics;
        });
    };

    const list = useMemo(() => Object.entries(graphics).map(([key, value], index) => (
        <div className="mb-2" key={key}>AREA {index + 1} - {value?.rings[0]?.length - 1} points</div>
    )), [graphics]);

    return (
        <div className="page-content">
            <Container fluid>
                <BackLink />

                <Container fluid className="mt-3">
                    <Row>
                        <Col sm="12" xxl="9" className="d-flex flex-column">
                            <div style={{height: "400px"}}>
                                <ArcGISMap
                                    onGeometryCreate={onGeometryCreate}
                                    onGeometryDelete={onGeometryDelete}
                                />
                            </div>
                        </Col>
                        <Col sm="12" xxl="3" className="d-flex flex-column">
                            <div className="card">
                                <div className="card-header">MAP AREAS</div>
                                <div className="card-body">
                                    {list.length ? list : "No selected areas"}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </Container>
            <ToastContainer />
        </div>
    );
};

PocMapPage.propTypes = {
    t: PropTypes.any,
    addGeometryDispatch: PropTypes.func,
};

export default withTranslation()(compose(
    connect(null,
        (dispatch) => ({
        addGeometryDispatch: bindActionCreators(addGeometry, dispatch),
    }),
))(PocMapPage));
