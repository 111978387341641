import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import withRouter from "./withRouter";
import classNames from "classnames";

const BackLink = ({ onClick = null, disabled = false }) => {
    const history = useNavigate();

    const handleOnClick = () => {
        if (disabled) return;

        if (onClick) {
            onClick();
        } else {
            history(-1);
        }
    };

    return (
        <span
            className={classNames('font-size-16 fw-medium', { 'text-primary': !disabled, 'text-muted': disabled})}
            style={{cursor: 'pointer'}}
            onClick={handleOnClick}
        >&lt; Back</span>
    );
};

BackLink.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

export default withRouter(BackLink);
