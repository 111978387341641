import PropTypes from "prop-types";

const Cell = ({
    value = undefined,
    isLoading = false,
    loadingValue = '?',
    defaultValue = '-',
    unit = undefined,
    suffix = undefined,
    isFloatNumber = false,
}) => {
    if (isLoading) {
        return loadingValue;
    }

    const formattedUnit = unit ? ` ${unit}` : '';
    const formattedValue = value && isFloatNumber ? value.toFixed(2): value;

    return (
        <>
            {value ? `${formattedValue}${formattedUnit}` : defaultValue}
            {(value && suffix) ? <sup>{suffix}</sup> : ''}
        </>
    );
};

Cell.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isLoading: PropTypes.bool,
    unit: PropTypes.string,
    suffix: PropTypes.string,
    defaultValue: PropTypes.string,
    loadingValue: PropTypes.string,
    isFloatNumber: PropTypes.bool,
};

export default Cell;
