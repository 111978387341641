import React from "react";

// Profile
import UserProfile from "../pages/Authentication/UserProfile";

// Authentication related pages
import Logout from "../pages/Authentication/Logout";

// Dashboard
import DashboardPage from "../pages/Dashboard";
import DevicesPage from "../pages/Devices";
import LandingPage from "../pages/Landing";
import DeviceDetailsPage from "../pages/DeviceDetails";
import UsersPage from "../pages/Users";
import UserDetailsPage from "../pages/UserDetails";
import PageNotFound from "../pages/PageNotFound";
import MyDevicesPage from "../pages/MyDevices";
import DeviceWarrantyRegistrationPage from "../pages/DeviceWarrantyRegistrationPage";
import PocMapPage from "../pages/PocMap";
import QuotesPage from "../pages/Quotes";

export const pageNotFoundRoute = { path: "*", component: <PageNotFound /> };
const commonAuthProtectedRoutes = [
  { path: "/", component: <LandingPage /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/poc-map", component: <PocMapPage /> },
  pageNotFoundRoute,
];

const authProtectedRoutesForAdmin = [
  { path: "/dashboard", component: <DashboardPage /> },
  { path: "/machines", component: <DevicesPage /> },
  { path: "/machines/:id", component: <DeviceDetailsPage /> },
  { path: "/users/:id", component: <UserDetailsPage /> },
  { path: "/users", component: <UsersPage /> },
  { path: "/quotes", component: <QuotesPage /> },
  ...commonAuthProtectedRoutes,
];

const authProtectedRoutesForUser = [
  { path: "/machines", component: <MyDevicesPage /> },
  { path: "/machines/:id", component: <DeviceDetailsPage /> },
  { path: "/machines/:id/warranty-registration", component: <DeviceWarrantyRegistrationPage /> },
  { path: "/machines/:id/warranty-registration", component: <DeviceWarrantyRegistrationPage /> },
  ...commonAuthProtectedRoutes,
];

const authProtectedRoutesForUnregistered = [
  pageNotFoundRoute,
];

const publicRoutes = [

  { path: "/logout", component: <Logout /> },
];

export {
  authProtectedRoutesForAdmin,
  authProtectedRoutesForUser,
  authProtectedRoutesForUnregistered,
  publicRoutes,
};
