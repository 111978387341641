import React, {useEffect, useRef} from "react";
import Graphic from "@arcgis/core/Graphic";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import PropTypes from "prop-types";

const MapWithMarker = ({ defaultCenter }) => {
    const mapRef = useRef(null);

    useEffect(() => {
        if (mapRef.current && defaultCenter.lat && defaultCenter.lng) {
            const map = new Map({
                basemap: "streets",
            });

            const view = new MapView({
                center: [defaultCenter.lng, defaultCenter.lat],
                container: mapRef.current,
                map: map,
                zoom: 13,
            });

            const point = {
                    type: "point", // autocasts as new Point()
                    longitude: defaultCenter.lng,
                    latitude: defaultCenter.lat,
                };

            const markerSymbol = {
                type: "picture-marker", // autocasts as new PictureMarkerSymbol()
                url: "../map-marker.svg",
                width: "35px",
                height: "35px",
                yoffset: "10",
            };

            // Create a graphic and add the geometry and symbol to it
            const pointGraphic = new Graphic({
                geometry: point,
                symbol: markerSymbol,
            });

            // Add the graphic to the view's graphics layer
            view.graphics.add(pointGraphic);
        }
    }, [defaultCenter]);

    return <div style={{ height: "100%", width: "100%" }} ref={mapRef}></div>;
};

MapWithMarker.propTypes = {
    defaultCenter: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
    }),
};

export default MapWithMarker;
