import React, { useEffect, useMemo } from "react";
import { Container } from "reactstrap";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { fetchUser } from "../../store/user/actions";
import UserLinkedMachineList from "./components/list";
import { selectIsUserDetailsLoading, selectUserDetails } from "../../store/user/selectors";
import { PROP_TYPE_USER } from "../../common/types";
import { createStructuredSelector } from "reselect";
import UserDetails from "./components/details";
import BackLink from "../../components/Common/BackLink";
import Spinner from "../../components/Common/Spinner";
import ScreenOverlay from "../../components/Common/ScreenOverlay";
import { getAllUserRelatedMachines } from "./helper";

const UserDetailsPage = ({ fetchUserDispatch = () => {}, data = null, isLoading = false }) => {
    const { id } = useParams();

    useEffect(() => {
        fetchUserDispatch(id);
    }, []);

    const machines = useMemo(() => getAllUserRelatedMachines(data), [data]);

    return (
        <div className="page-content">
            <Container fluid>
                <BackLink />
                <UserDetails data={data} />
                <UserLinkedMachineList data={machines}/>
                {isLoading && (
                    <>
                        <ScreenOverlay />
                        <Spinner />
                    </>
                )}
            </Container>
        </div>
    );
};

UserDetailsPage.propTypes = {
    data: PropTypes.shape(PROP_TYPE_USER),
    isLoading: PropTypes.bool,
    fetchUserDispatch: PropTypes.func.isRequired,
};

export default compose(
    connect(createStructuredSelector({
            data: selectUserDetails(),
            isLoading: selectIsUserDetailsLoading(),
        }),
    (dispatch) => ({
        fetchUserDispatch: bindActionCreators(fetchUser, dispatch),
    }),
))(UserDetailsPage);
