import { createSelector } from "reselect";
import { INIT_STATE } from "./reducer";

export const selectQuoteDomain = (state) => state.Quote || INIT_STATE;

export const selectQuoteList = () =>
    createSelector(selectQuoteDomain, ({ list }) => list);

export const selectIsQuoteListLoading = () =>
    createSelector(selectQuoteDomain, ({ isListLoading }) => isListLoading);
