import PropTypes from "prop-types";
import DashboardWidget from "../dashboard-widget";
import { PROP_TYPE_USER } from "../../../../common/types";

const UsersWidget = ({ totalCount = 0, recentUsers = [], isLoading = false }) => {
    return (
        <DashboardWidget
            title="Users"
            totalCountDescr="Total Users"
            recentCountDescr="New Users in the last 30 Days"
            buttonName="Users List"
            buttonLink="/users"
            totalCount={totalCount}
            recentCount={recentUsers?.length}
            isLoading={isLoading}
        />
    );
};

UsersWidget.propTypes = {
    totalCount: PropTypes.number,
    recentUsers: PropTypes.arrayOf(PropTypes.shape(PROP_TYPE_USER)),
    isLoading: PropTypes.bool,
};

export default UsersWidget;
