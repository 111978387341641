import React, { useEffect } from "react";
import { Container } from "reactstrap";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { fetchDevice } from "../../store/device/actions";
import { createStructuredSelector } from "reselect";
import { selectIsSelectedItemLoading, selectSelectedItem } from "../../store/device/selectors";
import { PROP_TYPE_MACHINE } from "../../common/types";
import DeviceDetails from "./components/details";
import BackLink from "../../components/Common/BackLink";
import ToastContainer from "../../components/Common/ToastContainer";
import useAuthUser from "../../common/hooks/authUser";

const DeviceDetailsPage = ({ fetchDeviceDispatch, data = null, isLoading = false}) => {
    const { isAdmin } = useAuthUser();
    const { id } = useParams();

    useEffect(() => {
        fetchDeviceDispatch(id);
    }, [fetchDeviceDispatch]);

    return (
        <div className="page-content">
            <Container fluid>
                <BackLink />

                <DeviceDetails
                    data={data}
                    isLoading={isLoading}
                    isAdmin={isAdmin}
                />
            </Container>
            <ToastContainer />
        </div>
    );
};

DeviceDetailsPage.propTypes = {
    fetchDeviceDispatch: PropTypes.func.isRequired,
    data: PropTypes.shape(PROP_TYPE_MACHINE),
    isLoading: PropTypes.bool,
};

export default compose(
    connect(createStructuredSelector({
            data: selectSelectedItem(),
            isLoading: selectIsSelectedItemLoading(),
        }),
        (dispatch) => ({
            fetchDeviceDispatch: bindActionCreators(fetchDevice, dispatch),
    }),
)(DeviceDetailsPage));
