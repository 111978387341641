import PropTypes from "prop-types";
import DashboardWidget from "../dashboard-widget";
import { PROP_TYPE_MACHINE } from "../../../../common/types";

const MachinesWidget = ({ totalCount = 0, recentMachines = [], isLoading = false }) => {
    return (
        <DashboardWidget
            title="Machines"
            totalCountDescr="Registered Machines"
            recentCountDescr="New Registrations in the last 30 Days"
            buttonName="Machines List"
            buttonLink="/machines"
            totalCount={totalCount}
            recentCount={recentMachines?.length}
            isLoading={isLoading}
        />
    );
};

MachinesWidget.propTypes = {
    totalCount: PropTypes.number,
    recentMachines: PropTypes.arrayOf(PropTypes.shape(PROP_TYPE_MACHINE)),
    isLoading: PropTypes.bool,
};

export default MachinesWidget;
