import {
  ADD_GEOMETRY,
  GET_QUOTE_LIST,
  GET_QUOTE_LIST_FAIL,
  GET_QUOTE_LIST_SUCCESS,
} from "./actionTypes";

export const addGeometry = (payload) => ({
  type: ADD_GEOMETRY,
  payload,
});

export const fetchQuoteList = (payload) => ({
    type: GET_QUOTE_LIST,
    payload,
});

export const fetchQuoteListSuccess = data => ({
    type: GET_QUOTE_LIST_SUCCESS,
    payload: data,
});

export const fetchQuoteListFail = data => ({
    type: GET_QUOTE_LIST_FAIL,
    payload: data,
});