import {
  GET_QUOTE_LIST,
  GET_QUOTE_LIST_SUCCESS,
  GET_QUOTE_LIST_FAIL,
} from "./actionTypes";

export const INIT_STATE = {
  list: [],
  isListLoading: false,
};

const Quote = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_QUOTE_LIST:
      return {
          ...state,
          list: [],
          isListLoading: true,
      };
    case GET_QUOTE_LIST_SUCCESS:
      return {
          ...state,
          list: action.payload?.data,
          isListLoading: false,
      };
    case GET_QUOTE_LIST_FAIL:
      return {
          ...state,
          list: [],
          isListLoading: false,
      };
    default:
      return state;
  }
};

export default Quote;
