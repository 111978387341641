import { API_URL } from "../config";

export const addTestGeometry = async (geometry) => {
    const response = await fetch(`${API_URL}/Quote/TestGeometry`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(geometry),
    });

    return response.json();
};

export const getAllQuotes = async () => {
    const response = await fetch(`${API_URL}/Quote/GetAllQuotes`);
    return response.json();
};
