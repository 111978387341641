import { Link } from "react-router-dom";
import { getFormattedDate } from "../../helpers/date_time_helper";

export const GET_COLUMNS = [
    {
        header: "Quote Id",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps) => (
            <Link to={`${cellProps.row.original.id}`}>
                {cellProps.row.original.id}
            </Link>
        ),
    },
    {
        header: 'Date Created',
        accessorKey: 'dateCreated',
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps) => getFormattedDate(cellProps.row.original.dateCreated),
    },
    {
        header: 'Contact Name',
        accessorKey: 'contactName',
        enableColumnFilter: false,
        enableSorting: false,
    },
    {
        header: 'Contact Phone #',
        accessorKey: 'contactPhone',
        enableColumnFilter: false,
        enableSorting: false,
    },
    {
        header: 'Status',
        accessorKey: 'status',
        enableColumnFilter: false,
        enableSorting: false,
    },
    {
        header: 'Job',
        accessorKey: 'job',
        enableColumnFilter: false,
        enableSorting: false,
    },
];
